function formatSecondsToSrcTime(seconds) {
  // Calculate minutes, seconds, and milliseconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const milliseconds = Math.round((seconds - Math.floor(seconds)) * 1000);

  // Ensure milliseconds are always represented by three digits
  const paddedMilliseconds = milliseconds.toString().padStart(3, '0');

  // Construct the formatted time string
  let formattedTime = "";
  if (minutes > 0) {
      formattedTime += `${minutes}m `;
  }
  if (remainingSeconds > 0 || minutes > 0) {
      formattedTime += `${remainingSeconds}s `;
  }
  formattedTime += `${paddedMilliseconds}ms`;

  return formattedTime;
}

export default formatSecondsToSrcTime