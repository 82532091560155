import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ImageList,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    Stack,
    Typography,
    Divider,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
//import { mapListData } from '../../data/mapList.js';
import CopyableCode from '../../components/CopyableCode.js';

function TitlebarImageList({ windowWidth, mapListData }) { // Destructuring windowWidth from props
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelectToggle = (code) => {
    const index = selectedItems.indexOf(code);
    const newSelectedItems = [...selectedItems];
    if (index === -1) {
      newSelectedItems.push(code);
    } else {
      newSelectedItems.splice(index, 1);
    }
    setSelectedItems(newSelectedItems);
  }

  const getColumnCount = (windowWidth) => {
    if (windowWidth < 700) {
      return 1
    } else if (windowWidth >= 700 && windowWidth < 1080) {
      return 2
    } else {
      return 3
    }
  }

  const toggleSubtitleMode = (item) => {
    const introductionParts = item.introduction.split('\n');
    return (
      <Stack sx={{py: "0px"}}>
        {introductionParts.map((part, index) => (
          <Typography key={index} variant='body1'>
            {part}
          </Typography>
        ))}
      </Stack>
    );
  };

  const CustomImageListItemBar = ({item}) => {
    if (selectedItems.indexOf(item.code) === -1) {
      return(
        <ImageListItemBar
          title={
            <Typography variant='h6'>{item.title}</Typography>
          }
          subtitle={item.creator}
          sx={{
            background: 'rgba(0, 0, 0, 0.7)',
            height: 'inherit',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleItemSelectToggle(item.code)
          }}
          // actionIcon={
          //   <IconButton
          //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
          //     aria-label={`info about ${item.title}`}
          //   >
          //     <InfoIcon/>
          //   </IconButton>
          // }
        />
      )
    } else {
      return(
        <ImageListItemBar
          title={
            <>
              <Typography variant='h6'>{item.title}</Typography>
              <CopyableCode item={item} passedPX={"1px"}/>
              {/* {item.introduction.length > 0 ? <Divider/> : <></>} */}
            </>
          }
          subtitle={toggleSubtitleMode(item)}
          sx={{
            background: 'rgba(0, 0, 0, 0.9)',
            height: '100%',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleItemSelectToggle(item.code)
          }}
          // actionIcon={
          //   <IconButton
          //     sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
          //     aria-label={`info about ${item.title}`}
          //   >
          //     <InfoIcon/>
          //   </IconButton>
          // }
        />
      )
    }
  }

  return (
    <ImageList cols={getColumnCount(windowWidth)}
      //sx={{ width: 500, height: 450 }}
      >
      {/* <ImageListItem key="Subheader" cols={2}>
      </ImageListItem> */}
      {mapListData.map((item) => (
        <ImageListItem 
          key={item.code}
          sx={{
          cursor: 'pointer',
          }}
        >
          <img
            srcSet={`${item.image_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.image_url}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
            onClick={() => {
              handleItemSelectToggle(item.code)
            }}
          />
          <CustomImageListItemBar item={item}/>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

TitlebarImageList.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

export default TitlebarImageList