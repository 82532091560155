import * as React from 'react';
import { useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Box from '@mui/material/Box';
import InformationButton from "../../components/informationButton.tsx"
import OneButtonToggle from '../../components/oneButtonToggle.js';

const SpeedrunRankedTable = ({ tableData, playerMetricsFilters, handlePlayerMetricsFiltersChange }) => {  
  const data = addPlayerKey(tableData)
  //const loading = showLoading

  function addPlayerKey(obj) {
    if (Object.keys(obj).length === 0) return [];

    return Object.entries(obj).map(([key, value]) => ({
        ...value,
        player: key
    }));
}
 
  const columns = useMemo(
    () => [
      {
        accessorKey: 'points',
        header: 'Points',
        size: 1,
      },
      {
        accessorKey: 'player',
        header: 'Player',
        size: 2,
        enableSorting: false,
      },
      {
        accessorKey: 'firsts',
        header: 'WRs',
        size: 1,
      },
      {
        accessorKey: 'seconds',
        header: '2nds',
        size: 1,
      },
      {
        accessorKey: 'thirds',
        header: '3rds',
        size: 1,
      },
    ],
    [],
  );
  
  
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableHiding: false,
    showLoadingOverlay: true,
    //to simplify table below
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: true,
    enableGlobalFilter: true,
    enableColumnActions: false,
    //to simplyfy table above
    initialState: {
      density: "compact",
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: true,
      sorting: [
        {
          id: 'points',
          desc: true,
        },
      ]
    },
    state: {
      isLoading: data.length < 1, //cell skeletons and loading overlay
    },
    muiTableContainerProps: { sx: { maxHeight: '67vh' } },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{ 
          display: 'flex',
          gap: '0.3rem',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <InformationButton/>
        <OneButtonToggle
          button1={"Normal"}
          onChange={handlePlayerMetricsFiltersChange}
          isActive={playerMetricsFilters.normal}
        />
        <OneButtonToggle
          button1={"Glitched"}
          onChange={handlePlayerMetricsFiltersChange}
          isActive={playerMetricsFilters.glitched}
        />
        <OneButtonToggle
          button1={"Full Game"}
          onChange={handlePlayerMetricsFiltersChange}
          isActive={playerMetricsFilters.fullGame}
        />
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default SpeedrunRankedTable;
