import axios from 'axios'

const levels = {
  "wj7oqn0w": "Airborne",
  "wo7gpzj9": "Airborne 2",
  "xd1el86w": "Alpine",
  "ldy7l8zd": "Anaconda",
  "wp7k6gyw": "Anarchy Arches",
  "nwlx42gw": "Anarchy Arches 2",
  "d1jj1ryd": "Azure Grotto",
  "9m55z30d": "Basalt Burrow",
  "5d7kqzvw": "Bone Cavern",
  "xd0r631d": "Bone Cavern 2",
  "gdr46ykw": "Borealis",
  "9m5kolzd": "Buddy Beach",
  "29ven5nd": "Cliff Runner",
  "r9g0q72w": "Cliff Runner 2",
  "wo7gvx39": "Conduit",
  "kwjl41zd": "Curvy Canyon",
  "ldy7l87d": "Curvy Canyon 2",
  "rdq2451d": "Day Drifting",
  "dnokkp5w": "Day Drifting 2",
  "d1jvo1yd": "Delirium",
  "y9mq411d": "Dust Up",
  "ywekxq4w": "Dust Up 2",
  "5wkz41qw": "Festive Falls",
  "dy144ppd": "Festive Falls 2",
  "dqz6vqgd": "Jackrabbit",
  "592genrw": "K2 Raceway",
  "rdnj1169": "K2 Raceway Deux",
  "wp775y4w": "Lavish Lagoon",
  "owo14qy9": "Lazy Lake",
  "z98vkk1w": "Lazy Lake 2",
  "d1jvqgzd": "Mine Mayhem",
  "dqzzqrgd": "Obsidian",
  "z98v4g1w": "Olympus",
  "rw6p5e79": "Outpost",
  "z98v4glw": "Pleasant Pitstop",
  "xd47grpd": "Puddle Jumper",
  "69zoe84d": "Puddle Jumper 2",
  "wlgkqrg9": "Python",
  "rdnj4269": "Riviera",
  "d7yyr5ed": "Seafoam Cove",
  "we2zr64w": "Seaside Farms",
  "ewpm40zw": "Shipwrecked",
  "rdnj42v9": "Sidewinder",
  "wj77rj1w": "Skull Rock Isle",
  "wj7omr1w": "Slap Happy",
  "n935zyrw": "Tilted Turnpike",
  "d7y60red": "Tri-City",
  "wo77xn39": "Twin Flame Island",
  "n935zyew": "Windy Way",
  "dnokv86w": "Aero",
  "drpkv7kw": "Anaconda Bite",
  "wlgkveg9": "Dusted",
  "9gyko829": "Inverse Arches",
  "wp7kv54w": "Olympico",
  "dy14v67d": "Pleasant Detour",
  "we2z7p4w": "Puddle Skipper",
  "95kv0n49": "Riviera Rush",
  "9x1mv07d": "Sidewinder Sprint",
  "9zpmv04w": "Tilted Turns"
}

async function resolveLevelNameFromId(levelId) {
  if(!levelId){
    return "Multi-Track"
  }

  if(levels[levelId]){
    return levels[levelId]
  }

  const url = `https://www.speedrun.com/api/v1/levels/${levelId}`
  const sessionStorageReturn = sessionStorage.getItem(url);

  if(sessionStorageReturn){
    return sessionStorageReturn
  } else {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: { }
    };
  
    try {
      const response = await axios.request(config);
      //console.log(response.data.data.name);
      sessionStorage.setItem(url, response.data.data.name);
      return response.data.data.name
    } catch (error) {
      console.error(error);
    }
  }
}

export default resolveLevelNameFromId