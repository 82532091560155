import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios'
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppAppBar from '../../components/AppAppBar';
import getLPTheme from '../getLPTheme';
import MapListText from '../MapListText/MapListText';
import MapListTiles from '../mapListTiles/MapListTiles';
import Footer from '../../components/Footer';
import PrivacyPolicy from '../Privacy/PrivacyPolicy';
import SRCLeaderboards from '../speedrunLeaderboardViewer/SpeedrunLeaderboardNew';
import LoginButton from '../../components/LoginButton.tsx';
import Authentication from '../../components/Authentication.tsx';
import SpeedrunRankedTable from '../SpeedrunRankedView/speedrunRankedTable.js';
import SpeedrunOldestRecordsTable from '../SpeedrunRankedView/speedrunOldestRecords.js';
import processOldestWrTableData from '../SpeedrunRankedView/processOldestWrTableData.js';
import resolvePlayerNameFromId from '../../utils/resolvePlayerNameFromId.js';
import AdminMapList from '../AdminMapList/AdminMapList.js';

export default function LandingPage() {

  const subCategories = {
    normal: ["lmoxkd81", "14o2gxjq"],
    glitched: ["1w4mpnmq", "1w4m23mq", "192zy4kq"]
  }
  const fullGameCategories = ["02q68o7k", "zdngr1nk", "q25z87j2", "jdry17n2"]

  const [mode, setMode] = React.useState('dark');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [mapList, setMapList] = React.useState([])
  const [mapListFiltered, setMapListFiltered] = React.useState([])
  const [mapListMode, setMapListMode] = React.useState(false)
  const [srcRunsAll, setSrcRunsAll] = React.useState([])
  const [playerMetrics, setPlayerMetrics] = React.useState({});
  const [playerMetricsWithNames, setPlayerMetricsWithNames] = React.useState({});
  const [oldestWRs, setOldestWRs] = React.useState({});
  const [cognitoGroups, setCognitoGroups] = React.useState([]);
  //Can contain "normal", "glitched", "fullGame"
  const [playerMetricsFilters, setPlayerMetricsFilters] = React.useState({
    glitched: true, 
    normal: true,
    fullGame: true
  });

  const handlePlayerMetricsFiltersChange = (newFormats, event) => {
    if (event.target.ariaLabel === "Normal") {
      setPlayerMetricsFilters({
        ...playerMetricsFilters,
        normal: event.target.ariaPressed === "true" ? false : true
      })
    }
    if (event.target.ariaLabel === "Glitched") {
      setPlayerMetricsFilters({
        ...playerMetricsFilters,
        glitched: event.target.ariaPressed === "true" ? false : true
      })
    }
    if (event.target.ariaLabel === "Full Game") {
      setPlayerMetricsFilters({
        ...playerMetricsFilters,
        fullGame: event.target.ariaPressed === "true" ? false : true
      })
    }
  }

  const mapListModeToggle = () => {
    setMapListMode(!mapListMode)
  }

  //Fetching Map List
  React.useEffect(() => {
    const sortByCreatedDate = (items) => {
      return items.sort((a, b) => {
        //.created is the most recent update
        //.published is the original creation date
        const dateA = new Date(a.published);
        const dateB = new Date(b.published);
        return dateB - dateA; // Sort in descending order (newest first)
      });
    };

    const filterArrayBySubstrings = (initialArray) => {
      const substringsTitle = ["skibidi", "finish", "ramp", "guess", "quiz", "impossible", "into", "obby", "only up", "onlyup", "funny", "infinite", "unlimited", "to the", "mrbeast"]
      const substringsIntroduction = ["mega ramp", "funny", "impossible", "hardest", "play with your friends", "smoothest road", "10 LAPS"]
      return initialArray.filter(item => {
        const lowercaseTitle = item.title.toLowerCase();
        const lowercaseIntroduction = item.introduction.toLowerCase();
        // Check if the lowercase title contains any of the lowercase substrings
        return (
          !substringsTitle.some(substring => lowercaseTitle.includes(substring.toLowerCase())) &&
          !substringsIntroduction.some(substring => lowercaseIntroduction.includes(substring.toLowerCase()))
        )
      });
    };

    const fetchData = async () => {
      try {
        // Check if the data is already stored in session storage
        const cachedData = sessionStorage.getItem('dateSortedMaps');
        if (cachedData) {
          // If data is already cached, parse and use it directly
          const parsedData = JSON.parse(cachedData);
          setMapList(parsedData);

          const dateSortedFilteredMaps = sessionStorage.getItem('dateSortedFilteredMaps');
          setMapListFiltered(JSON.parse(dateSortedFilteredMaps))
        } else {
          // If data is not cached, make the Axios request
          const config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_REQUEST_URL}/maps`,
            headers: {
              'x-api-key': process.env.REACT_APP_X_API_KEY
            }
          };
  
          const response = await axios.request(config);

          let responseArray = []
          for (const [key, value] of Object.entries(response.data)) {
            responseArray.push(JSON.parse(value))
          }
        
          const dateSortedMaps = sortByCreatedDate(responseArray)          
          setMapList(dateSortedMaps)
          sessionStorage.setItem('dateSortedMaps', JSON.stringify(dateSortedMaps));

          const dateSortedFilteredMaps = filterArrayBySubstrings(dateSortedMaps)
          setMapListFiltered(dateSortedFilteredMaps)
          sessionStorage.setItem('dateSortedFilteredMaps', JSON.stringify(dateSortedFilteredMaps));
        }
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  //Fetching Speedrun Leaderboard
  React.useEffect(() => {
    async function fetchAllData(apiUrl) {
      let combinedData = [];
      // Check if the data is already stored in session storage
      const cachedData = sessionStorage.getItem('srcRunsAll')
      if (cachedData) {
        // If data is already cached, parse and use it directly
        const parsedData = JSON.parse(cachedData);
        combinedData = parsedData
        setSrcRunsAll(parsedData)
      } else {
        let nextUrl = apiUrl;

        while (nextUrl) {
          try {
            const response = await axios.get(nextUrl);
            const jsonResponse = response.data;

            combinedData = combinedData.concat(jsonResponse.data || []);

            // Find the next URL, if it exists
            const nextLink = jsonResponse.pagination.links.find(link => link.rel === 'next');
            nextUrl = nextLink ? nextLink.uri : null;

          } catch (error) {
            console.error(`Error fetching data from ${nextUrl}:`, error);
            nextUrl = null;
          }
        }
        setSrcRunsAll(combinedData)
        sessionStorage.setItem('srcRunsAll', JSON.stringify(combinedData));
      } 

      // Find the fastest 3 verified runs for each unique level, category, and values combination
      const fastestRuns = findFastestRuns(combinedData);

      const fastestRunPerUniqueKey = extractFirstElements(fastestRuns)
      const oldestRecords = getOldestNumItems(fastestRunPerUniqueKey, 200)

      setOldestWRs(await processOldestWrTableData(oldestRecords))

      const metrics = calculatePlayerMetrics(fastestRuns);

      replaceKeysWithUsernames(metrics)
    }

    async function getUsernames(userIds) {
      const requests = userIds.map(async id => {
        const username = await resolvePlayerNameFromId(id);
        return { id, username: username !== id ? username : null };
      });
    
      const results = await Promise.all(requests);
      return results.reduce((acc, { id, username }) => {
        if (username) acc[id] = username;
        return acc;
      }, {});
    }
    
    async function replaceKeysWithUsernames(data) {
      const userIds = Object.keys(data);
      const usernamesMap = await getUsernames(userIds);
  
      const newData = {};
      for (const [id, stats] of Object.entries(data)) {
          const username = usernamesMap[id];
          if (username) {
              newData[username] = stats;
          }
      }

      //console.log(newData);

      setPlayerMetricsWithNames(newData)
      // sessionStorage.setItem('playerMetricsWithNames', JSON.stringify(newData));
    }

    function findFastestRuns(runs) {
      // Filter verified runs
      const verifiedRuns = runs.filter(run => run.status.status === 'verified');
    
      // Group by unique level, category, and values combination
      const groupedRuns = verifiedRuns.reduce((acc, run) => {
        const valuesKey = Object.entries(run.values).map(([key, value]) => `${key}-${value}`).join('-');
        const key = `${run.level}-${run.category}-${valuesKey}`;
        if (key.length > 35) {
          return acc; // Skip this iteration
        }//Filter for runs here:
        if (!playerMetricsFilters.normal && 
          subCategories.normal.some(value => key.includes(value))
      ) {
        return acc;
      }
      if (!playerMetricsFilters.glitched && 
          subCategories.glitched.some(value => key.includes(value))
      ) {
        return acc;
      }
      if (!playerMetricsFilters.fullGame && 
          fullGameCategories.some(value => key.includes(value))
      ) {
        return acc;
      }
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(run);
        return acc;
      }, {});    
    
      // Find the fastest runs for each combination with unique players
      const fastestRuns = {};
      for (const key in groupedRuns) {
        const runs = groupedRuns[key];
    
        // Create a map to store the fastest run per player
        const playerFastestRun = runs.reduce((acc, run) => {
          const playerId = run.players[0].id;
          if (!acc[playerId] || acc[playerId].times.primary_t > run.times.primary_t) {
            acc[playerId] = run;
          }
          return acc;
        }, {});
    
        // Convert the map back to an array and sort by time
        const uniquePlayerRuns = Object.values(playerFastestRun);
        uniquePlayerRuns.sort((a, b) => a.times.primary_t - b.times.primary_t);
    
        // Take the fastest 3 unique runs
        fastestRuns[key] = uniquePlayerRuns.slice(0, 3);
      }
    
      return fastestRuns;
    }

    function extractFirstElements(obj) {
      // Initialize a new object to store the result
      const result = {};
      
      // Iterate over each key in the input object
      for (const key in obj) {
          // Check if the property is indeed part of the object (and not inherited)
          if (obj.hasOwnProperty(key)) {
              // Assign the value at index 0 of the array to the new object
              result[key] = obj[key][0];
          }
      }
      
      return result;
    }

    function getOldestNumItems(obj, num) {
      // Convert the object into an array of [key, value] pairs
      const entries = Object.entries(obj);
  
      // Sort the array by the submitted date
      entries.sort((a, b) => new Date(a[1].submitted) - new Date(b[1].submitted));
  
      // Extract the first three items from the sorted array
      const oldestThreeEntries = entries.slice(0, num);
  
      // Convert the sorted entries back into an object
      const result = {};
      for (const [key, value] of oldestThreeEntries) {
          result[key] = value;
      }
  
      return result;
    }

    function calculatePlayerMetrics(fastestRuns) {
      const metrics = {};

      for (const key in fastestRuns) {
        fastestRuns[key].forEach((run, index) => {
          const playerId = run.players[0].id;
          if (!metrics[playerId]) {
            metrics[playerId] = {
              points: 0,
              firsts: 0,
              seconds: 0,
              thirds: 0,
            };
          }

          if (index === 0) {
            metrics[playerId].points += 3;
            metrics[playerId].firsts += 1;
          } else if (index === 1) {
            metrics[playerId].points += 2;
            metrics[playerId].seconds += 1;
          } else if (index === 2) {
            metrics[playerId].points += 1;
            metrics[playerId].thirds += 1;
          }
        });
      }

      return metrics;
    }

    fetchAllData('https://www.speedrun.com/api/v1/runs?game=9doxkvod&offset=0&max=200');
  }, [playerMetricsFilters]);
  

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check login status  
  React.useEffect(() => {
    const fetchSession = async () => {
      const session = await fetchAuthSession();
      // console.log(session);
      //console.log(session?.tokens.idToken.toString());
      const groups = session?.tokens?.idToken?.payload["cognito:groups"]
      if (groups) {
        setCognitoGroups(groups);
        sessionStorage.setItem("cognitoGroups", session?.tokens?.idToken?.payload["cognito:groups"])
      }
    };
    
    const sessionCognitoGroups = sessionStorage.getItem(cognitoGroups)
    if (sessionCognitoGroups) {
      setCognitoGroups(sessionCognitoGroups)
    } else {

    }
    fetchSession();
  }, []);
  

  const [mapView, setMapView] = React.useState(windowWidth < 700 ? 'tile' : 'list');

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleViewMode = () => {
    setMapView((prev) => (prev === 'list' ? 'tile' : 'list'));
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Stack>
        <AppAppBar 
          mode={mode} 
          toggleColorMode={toggleColorMode} 
          mapView={mapView}
          toggleViewMode={toggleViewMode}
          windowWidth={windowWidth}
          showAdminButton={cognitoGroups && cognitoGroups.includes("admin")}
        />
        <Box sx={{ bgcolor: 'background.default', marginTop: "55px", marginBottom: "1px"}}>
          <Router>
            <Routes>
              <Route path="/" element={mapView === "list"
                ? <MapListText 
                    setOpenSnackbar={setOpenSnackbar}
                    mapListData={mapListMode ? mapListFiltered : mapList}
                    hideBadMapsHandler={mapListModeToggle}
                    hideBadMapsActive={mapListMode}
                  />
                : <MapListTiles windowWidth={windowWidth} mapListData={mapListMode ? mapListFiltered : mapList}/>} />
              <Route path="/privacy" element={<PrivacyPolicy/>} />
              <Route path="/login" element={<Authentication/>}/>
              <Route path="/leaderboard" element={
                <SpeedrunRankedTable 
                  tableData={playerMetricsWithNames}
                  playerMetricsFilters={playerMetricsFilters}
                  handlePlayerMetricsFiltersChange={handlePlayerMetricsFiltersChange}
                />} 
              />
              <Route path="/srcstats" element={
                <SpeedrunOldestRecordsTable
                  tableData={oldestWRs}
                  playerMetricsFilters={playerMetricsFilters}
                  handlePlayerMetricsFiltersChange={handlePlayerMetricsFiltersChange}
                />} 
              />
              <Route path="/admin" element={
                cognitoGroups && cognitoGroups.includes("admin") ? 
                  <AdminMapList 
                    setOpenSnackbar={setOpenSnackbar}
                    mapListData={mapListMode ? mapListFiltered : mapList}
                    hideBadMapsHandler={mapListModeToggle}
                    hideBadMapsActive={mapListMode}
                  /> 
                : <></>} />
              {/* <Route path="/srcleaderboards" element={<SRCLeaderboards/>} /> */}
            </Routes>
          </Router>
        </Box>
      </Stack>
      <Footer/>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={() => setOpenSnackbar(false)}
        message="Code Copied!"
        action={action}
      />
    </ThemeProvider>
  );
}
