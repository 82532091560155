import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ViewListIcon from '@mui/icons-material/ViewList';
import GridViewIcon from '@mui/icons-material/GridView';

const ToggleViewMode = ({ mapView, toggleViewMode }) => {
  return (
    <Box sx={{ maxWidth: '32px' }}>
      <Button
        variant="contained"
        onClick={toggleViewMode}
        size="medium"
        aria-label="button to toggle map view"
        sx={{ minWidth: '32px', height: '32px', p: '4px' }}
      >
        {mapView === 'tile' ? (
          <ViewListIcon fontSize="medium" />
        ) : (
          <GridViewIcon fontSize="medium" />
        )}
      </Button>
    </Box>
  );
}

ToggleViewMode.propTypes = {
  mapView: PropTypes.oneOf(['list', 'tile']).isRequired,
  toggleViewMode: PropTypes.func.isRequired,
};

export default ToggleViewMode;
