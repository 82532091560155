import axios from "axios";
import { fetchAuthSession } from 'aws-amplify/auth';

function convertTagsStringToArray(inputString){
  // Use the split method to divide the string into an array at each comma
  let resultArray = inputString.split(',');
  // Trim any extra spaces from each array element
  resultArray = resultArray.map(item => item.trim());
  return resultArray;
}

async function editUefnMapDbEntry(values) {
  values = {
    ...values,
    tags: convertTagsStringToArray(values.tags)
  }
  try {
    const session = await fetchAuthSession();
    const data = {
      payload: values
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_REQUEST_URL}/maps/admin`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY,
        'Authorization': session?.tokens?.idToken.toString()
      },
      data: JSON.stringify(data)
    };

    const response = await axios.request(config);
    console.log(JSON.stringify(response.data));
  } catch (error) {
    console.error("Axios request error:", error);
    if (error.response) {
      console.error("Error response data:", error.response.data);
    }
  }
}

export default editUefnMapDbEntry;
