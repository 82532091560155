import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import LandingPage from './pages/LandingPage/LandingPage'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Authenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme

const queryClient = new QueryClient()

export default function App() {
  return (
    <Authenticator.Provider>
      <View>
        <QueryClientProvider client={queryClient}>
          <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
              <LandingPage />
            </Box>
          </Container>
        </QueryClientProvider>
      </View>
    </Authenticator.Provider>
  );
}
