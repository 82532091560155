import axios from 'axios'
import resolveLevelNameFromId from '../../utils/resolveLevelNameFromId';
import resolvePlayerNameFromId from '../../utils/resolvePlayerNameFromId';
import resolveCategoryNameFromId from '../../utils/resolveCategoryNameFromId';
import formatSecondsToSrcTime from '../../utils/formatSecondsToSrcTime';

function daysSince(timestamp) {
  const referenceTimestamp = new Date();
  const givenTimestamp = new Date(timestamp);
  
  const differenceInMilliseconds = givenTimestamp - referenceTimestamp;
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  
  const differenceInDays = differenceInMilliseconds / millisecondsInADay;
  
  return Math.floor(differenceInDays) * -1;
}

async function processOldestWrTableData (tableData) {
  const data = []
  for(let key in tableData){
    if(tableData.hasOwnProperty(key)){
      const element = tableData[key]
      data.push({
        age: daysSince(element.submitted),
        map: await resolveLevelNameFromId(element.level),
        category: await resolveCategoryNameFromId(element.category),
        time: formatSecondsToSrcTime(element.times.primary_t),
        player: await resolvePlayerNameFromId(element.players[0].id)
      })
    }
  }
  return data
}

export default processOldestWrTableData