import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          This privacy policy applies to the FortniteRocket.Racing website (hereby referred to as "Website") for mobile devices that was created by Havvak (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information Collection and Use
        </Typography>
        <Typography variant="body1" paragraph>
          The Website collects information when you use it. This information may include information such as:
        </Typography>
        <Typography component="ul">
          <li>Your device's Internet Protocol address (e.g. IP address)</li>
          <li>The pages of the Website that you visit, the time and date of your visit, the time spent on those pages</li>
          <li>The time spent on the Website</li>
          <li>The operating system you use on your device</li>
        </Typography>
        <Typography variant="body1" paragraph>
          The Website does not gather precise information about the location of your device.
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider may use the information you provided to contact you from time to time to provide you with important information and required notices.
        </Typography>
        <Typography variant="body1" paragraph>
          For a better experience, while using the Website, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to an epic games account id. The information that the Service Provider requests will be retained by them and used as described in this privacy policy.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Third Party Access
        </Typography>
        <Typography variant="body1" paragraph>
          Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Website and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider may disclose User Provided and Automatically Collected Information:
        </Typography>
        <Typography component="ul">
          <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
          <li>when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
          <li>with their trusted services providers who work on their behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Opt-Out Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You can stop all collection of information by the Website easily by ceasing use of it.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Data Retention Policy
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider will retain User Provided data for as long as you use the Website and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Website, please contact them at havvakgalaxysloth@gmail.com and they will respond in a reasonable time.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Children
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider does not use the Website to knowingly solicit data from or market to children under the age of 13.
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider does not knowingly collect personally identifiable information from children. The Service Provider encourages all children to never submit any personally identifiable information through the Website and/or Services. The Service Provider encourages parents and legal guardians to monitor their children's Internet usage and to help enforce this Policy by instructing their children never to provide personally identifiable information through the Website and/or Services without their permission. If you have reason to believe that a child has provided personally identifiable information to the Service Provider through the Website and/or Services, please contact the Service Provider (havvakgalaxysloth@gmail.com) so that they will be able to take the necessary actions. You must also be at least 16 years of age to consent to the processing of your personally identifiable information in your country (in some countries we may allow your parent or guardian to do so on your behalf).
        </Typography>
        <Typography variant="h6" gutterBottom>
          Security
        </Typography>
        <Typography variant="body1" paragraph>
          The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Changes
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
        </Typography>
        <Typography variant="body1" paragraph>
          This privacy policy is effective as of 2024-04-17
        </Typography>
        <Typography variant="h6" gutterBottom>
          Your Consent
        </Typography>
        <Typography variant="body1" paragraph>
          By using the Website, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions regarding privacy while using the Website, or have questions about the practices, please contact the Service Provider via email at havvakgalaxysloth@gmail.com.
        </Typography>
      </Box>
    </Container>
  );
}

export default PrivacyPolicy;
