import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Stack from '@mui/material/Stack';

const InformationButton: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div id='closeBackdrop'>
      <InfoIcon 
        onClick={handleOpen}
        sx={{
          cursor: 'pointer',
          borderRadius: '999px',
          '&:hover': {
            bgcolor: "action.selected",
          }
        }}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Box 
          sx={{
            bgcolor: "background.default",
            borderWidth: 1,
            borderColor: "action.selected"
          }}
        >
          <Stack>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{
                px: '6px',
              }}
            >
              Each unique combination of Level, Category, and Subcategory awards points:
            </Typography>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{ 
                px: '6px',
              }}
            >
              - WR: 3 points
            </Typography>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{  
                px: '6px',
              }}
            >
              - 2nd: 2 points
            </Typography>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{ 
                px: '6px',
              }}
            >
              - 3rd: 1 point
            </Typography>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{
                paddingTop: '6px', 
                px: '6px',
              }}
            >
              Competitve Normal, Competitive Glitched, Speed Run Normal, Speed Run Glitched, and Full Game runs all award points. Categories can be filtered with the various buttons.
            </Typography>
            <Typography
              variant="h5" 
              color="text.primary"
              sx={{
                paddingTop: '6px',
                px: '6px',
              }}
            >
              Data is pulled from all verified runs on speedrun.com/Rocket_Racing
            </Typography>
          </Stack>
        </Box>
      </Backdrop>
    </div>
  );
};

export default InformationButton;
