import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ToggleColorMode from './ToggleColorMode';
import ToggleViewMode from './toggleMapViewMode';
import FRR_Logo from '../images/FRR_Logo_Transparent.png'
import FRR_Logo_Light from '../images/FRR_Logo_Light.png'
import Link from '@mui/material/Link';
import LoginButton from './LoginButton.tsx';


function AppAppBar({ 
  mode, 
  toggleColorMode, 
  mapView, 
  toggleViewMode,
  windowWidth,
  showAdminButton,
}) {
  // const [open, setOpen] = React.useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-10px',
                px: 0,
                gap: 0.5,
              }}
            >
              <Box
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  alignItems: 'center', // Add this line to vertically center items
                  gap: 1,
                }}
              >
                <Link href="/" underline="none">
                  <Box
                    component="img"
                    src={mode === 'dark' ? FRR_Logo : FRR_Logo_Light}
                    alt="FRR Logo"
                    loading="lazy"
                    sx={{
                      paddingLeft: '10px',
                      maxHeight: '50px',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                  />
                </Link>
                <Typography 
                  variant="h5" 
                  color="text.primary"
                  sx={{ 
                    paddingTop: '25px', 
                    px: '6px', 
                    fontFamily: 'HeadingNowHeavyItalic',
                  }}
                >
                  by Havvak
                </Typography>
                <Link href="/" underline="none">
                  <Button variant='contained'>UEFN Maps</Button>
                </Link>
                <Link href="/leaderboard" underline="none">
                  <Button variant='contained'>Leaderboard</Button>
                </Link>
                <Link href="/srcstats" underline="none">
                  <Button variant='contained'>SRC Stats</Button>
                </Link>
                {
                  showAdminButton && 
                  <Link href="/admin" underline="none">
                  <Button variant='contained'>ADMIN</Button>
                  </Link>
                }
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'flex', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleViewMode mapView={mapView} toggleViewMode={toggleViewMode}/>
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
              {/* <Link href="/login" underline="none">
                <Typography>
                  Login
                </Typography>
              </Link> */}
              {/* <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-in/"
                target="_blank"
              >
                Sign in
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-up/"
                target="_blank"
              >
                Sign up
              </Button> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  mapView: PropTypes.oneOf(['list', 'tile']).isRequired,
  toggleViewMode: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

export default AppAppBar;
