import * as React from 'react';
import PropTypes from 'prop-types';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';

const CopyableCode = ({item, passedPX}) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const copyToClipboard = (inputString) => {
    // Copy the selected text to the clipboard
    navigator.clipboard.writeText(inputString)
      .then(() => {
        setOpenSnackbar(true)
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }
  
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return(
    <Box
      display='flex' 
      onClick={(event) => {
        event.stopPropagation();
        copyToClipboard(item.code)
      }}
      sx={{
        visibility: "hidden",
        '&:hover': {
          visibility: "visible",
        }
      }}            
    >
      <Typography 
        variant='body1' 
        display='inline' 
        noWrap
        sx={{
          visibility: "visible",
          cursor: 'pointer',
          px: passedPX || '5px',
          borderRadius: '999px',
          '&:hover': {
            bgcolor: "action.selected",
          }
        }}>
        {item.code}
      </Typography>
      <ContentCopyIcon 
        fontSize='small'
        sx={{
          cursor: 'pointer',
          color: 'inherit',
          px: '2px',
        }}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={() => setOpenSnackbar(false)}
        message="Code Copied!"
        action={action}
      />
    </Box>
  )
}

CopyableCode.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CopyableCode