import * as React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function TwoButtonToggle({ button1, button2, onChange, oneMustBeActive=false }) {
  const [formats, setFormats] = React.useState(oneMustBeActive ? button1 : null);

  const handleFormat = (event, newFormats) => {
    if (oneMustBeActive) {
      if (newFormats !== null) {
        setFormats(newFormats);
        onChange(newFormats)
      }
    } else {
      onChange(newFormats)
      setFormats(newFormats);
    }
  };

  return (
    <ToggleButtonGroup
      size='small'
      value={formats}
      exclusive
      onChange={handleFormat}
      aria-label="text formatting"
    >
      <ToggleButton value={button1} aria-label={button1}>
        {button1}
      </ToggleButton>
      <ToggleButton value={button2} aria-label={button2}>
        {button2}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

TwoButtonToggle.propTypes = {
  button1: PropTypes.string.isRequired,
  button2: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TwoButtonToggle