import * as React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function OneButtonToggle({ button1, onChange, isActive = false }) {
  const [formats, setFormats] = React.useState(isActive ? button1 : null);

  const handleFormat = (event, newFormats) => {
    onChange(newFormats, event)
    setFormats(newFormats);
  };

  return (
    <ToggleButtonGroup
      size='small'
      value={formats}
      exclusive
      onChange={handleFormat}
      aria-label="text formatting"
    >
      <ToggleButton value={button1} aria-label={button1}>
        {button1}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

OneButtonToggle.propTypes = {
  button1: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default OneButtonToggle