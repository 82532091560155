import axios from "axios";

async function resolveCategoryNameFromId(categoryId) {
  if(!categoryId){
    return "N/A"
  }

  const url = `https://www.speedrun.com/api/v1/categories/${categoryId}`
  const sessionStorageReturn = sessionStorage.getItem(url);

  if(sessionStorageReturn){
    return sessionStorageReturn
  } else {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: { }
    };
  
    try {
      const response = await axios.request(config);
      //console.log(response.data.data.name);
      sessionStorage.setItem(url, response.data.data.name);
      return response.data.data.name
    } catch (error) {
      console.error(error);
    }
  }
}

export default resolveCategoryNameFromId